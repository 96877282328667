import { axiosInstance } from '../axios/axios-config';

export const SdaRequest = {
  getAllSda: async () => {
    if (!sessionStorage.getItem('userData')) {
      return []
    }
    try {
      let url = '/sda'
      if (sessionStorage.getItem('limitedSda')) {
        url += '?limitedSda=' + sessionStorage.getItem('limitedSda')
      }
      const response = await axiosInstance.get(url);
      return response.data;
    } catch (error) {
      console.error('Erreur lors de l\'obtention des SDA :', error);
      throw error;
    }
  },

  getSdaById: async (sdaName, dateStart, dateEnd) => {
    if(!sessionStorage.getItem('userData')) {
      return []
    }
    try {
      const params = { dateStart, dateEnd, agent };
      const response = await axiosInstance.get(`/sda/${sdaName}`, { params });
      return response.data;
    } catch (error) {
      console.error(`Erreur lors de l'obtention des détails du SDA ${sdaName} :`, error);
      throw error;
    }
  },

  getSdaDetails: async (sdaName, dateStart, dateEnd, agentId) => {
    if(!sessionStorage.getItem('userData')) {
      return []
    }
    try {
      const params = { dateStart, dateEnd, agent: agentId };
      const response = await axiosInstance.get(`/sda/${sdaName}`, { params });
      return response.data;
    } catch (error) {
      console.error(`Erreur lors de l'obtention des détails du SDA ${sdaName} :`, error);
      throw error;
    }
  }
};
