import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import NavGroup from './NavGroup';
import loadMenuItems from 'menu-items';

const Navigation = () => {
  const [menuItems, setMenuItems] = useState({ items: [] });

  useEffect(() => {
    const items = loadMenuItems();
    setMenuItems(items);
  }, []);

  const navGroups = menuItems.items.map((item) => {
    switch (item.type) {
      case 'group':
        return <NavGroup key={item.id} item={item} />;
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Fix - Navigation Group
          </Typography>
        );
    }
  });

  return <Box sx={{ pt: 2 }}>{navGroups}</Box>;
};

export default Navigation;
