import React from 'react';
import Navigation from './Navigation';
import SimpleBar from 'components/third-party/SimpleBar';
import FilterSelector from './filterSelector';

const DrawerContent = () => {
  return (
    <SimpleBar
      sx={{
        '& .simplebar-content': {
          display: 'flex',
          flexDirection: 'column'
        }
      }}
    >
      <FilterSelector />
      <Navigation />
      {/* <NavCard /> */}
    </SimpleBar>
  );
};

export default DrawerContent;
