const Logo = () => {

  return (
    <>
      <img src="https://www.snsolutions.fr/wp-content/uploads/2024/02/cropped-logo-sns-20-bis.png" height="60" width="200" alt="Logo SNS Solutions"/>
    </>
  );
};

export default Logo;
