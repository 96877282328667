import { useQuery } from 'react-query';
import { SdaRequest } from '../request/sda.request';

function useGetAllSda() {
  return useQuery('allSda', SdaRequest.getAllSda, {
    initialData: { allSda: [] }
  });
}

export default useGetAllSda;
