import {
    ChromeOutlined,
    QuestionOutlined,
    AuditOutlined,
    CommentOutlined,
    LockOutlined,
    QuestionCircleOutlined,
    UserOutlined
  } from '@ant-design/icons';
  
  const icons = {
    ChromeOutlined,
    QuestionOutlined,
    AuditOutlined,
    CommentOutlined,
    LockOutlined,
    QuestionCircleOutlined,
    UserOutlined
  };
  
  const getSupportMenu = () => {
    const isLimitedUser = sessionStorage.getItem('limitedSda') !== null;
    console.log('Is limited user (support menu):', isLimitedUser);
  
    return {
      id: 'support',
      title: 'Paramètres',
      type: 'group',
      children: [
        {
          id: 'support',
          title: 'Support',
          type: 'item',
          url: 'https://outlook.office365.com/mail/deeplink/compose?mailtouri=mailto%3Acontact%40snsolutions.fr',
          icon: icons.QuestionCircleOutlined,
          external: true,
          target: true
        },
        // Ajouter l'élément conditionnellement
        ...(!isLimitedUser ? [{
          id: 'addusers-page',
          title: 'Gestion des utilisateurs',
          type: 'item',
          url: '/addusers-page',
          icon: icons.ChromeOutlined
        }] : [])
      ]
    };
  };
  
  export default getSupportMenu;
  